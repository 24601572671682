
export enum DocumentTypeEnum {
  BREV = "21321311-0000-0000-0000-000000000078",
  PROSSESSKRIV = "21321311-0000-0000-0000-000000000079",
  DIVERSE = "21321311-0000-0000-0000-000000000080",
  EPOST = "21321311-0000-0000-0000-000000000081",
  NOTAT = "21321311-0000-0000-0000-000000000082",
  BILDE = "21321311-0000-0000-0000-000000000083",
  UT_FAKTURA = "21321311-0000-0000-0000-000000000084",
  OMKOSTNINGSOPPGAVE = "21321311-0000-0000-0000-000000000085",
  FAKS = "21321311-0000-0000-0000-000000000086",
  SIKKER_SENDING = "21321311-0000-0000-0000-000000000087",
  SIGN_RET = "21321311-0000-0000-0000-000000000088",
  SIGNERT = "21321311-0000-0000-0000-000000000089",
  DIGIPOST = "21321311-0000-0000-0000-00000000008A",
  SLETTET = "21321311-0000-0000-0000-00000000008B",
  UT_FAKTURA_SYS_GEN = "1E548585-2ED0-4A35-9C1B-F6598C79058D"
}

/*
  Legg til GUID fra DocumentTypeEnum her som ikke kan byttes til og ifra.
  Må være uppercase for at matching skal fungere
*/

// kan ikke endres
export const StaticDocumentTypes: string[] = [
  DocumentTypeEnum.NOTAT,
  DocumentTypeEnum.UT_FAKTURA,
  DocumentTypeEnum.UT_FAKTURA_SYS_GEN,
  DocumentTypeEnum.SIKKER_SENDING,
  DocumentTypeEnum.SIGN_RET,
  DocumentTypeEnum.DIGIPOST
]


/*
  Legg til GUID fra DocumentTypeEnum her som skal være beskyttet.
  Må være uppercase for at matching skal fungere
*/

// kan ikke slettes
export const ProtectedDocumentTypes: string[] = [
  DocumentTypeEnum.UT_FAKTURA,
  DocumentTypeEnum.UT_FAKTURA_SYS_GEN,
  DocumentTypeEnum.SIKKER_SENDING,
  DocumentTypeEnum.SIGN_RET,
  DocumentTypeEnum.DIGIPOST
]


export const AccountingDocumentTypes: string[] = [
  DocumentTypeEnum.UT_FAKTURA,
  DocumentTypeEnum.UT_FAKTURA_SYS_GEN,
]

